import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutFR from "../../composants/layout/fr/LayoutFR"
import ListeCartesTitreEtDescription from "../../composants/navigation/ListeCartesTitreEtDescription"
import Section from "../../composants/layout/Section"

import { obtenirPage, obtenirPages } from "../../js/client-es/utils"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageIndexTutoriels: allPagesCompilationYaml(
      filter: { code: { eq: "indexTutoriels" } }
    ) {
      ...FragmentPageYaml
    }
    pagesVoieLactee: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "photographierLaVoieLactee"
            "traitementImagesVoieLactee"
            "photographierArcheVoieLactee"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesAuroresBoreales: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "comprendreAuroresBoreales"
            "guideAuroresBoreales"
            "photographierAuroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesAstrophotographieCMOSCCD: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["paletteHubble12", "paletteHubble22", "paletteHubblePhotoshop"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesLogicielsMateriels: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielAstrophotographie"
            "logicielsAstrophotographie"
            "astrophotographiePhotoshop"
            "isoAstrophotographie"
            "testEOSRa"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    pagesAutresArticles: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["decouverteNebuleusePlanetaire", "previsionsCouvertureNuageuse"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
  }
`

export default function IndexTutoriels() {
  const resultatsRequete = useStaticQuery(requete)
  const pageIndexTutoriels = obtenirPage(resultatsRequete, "pageIndexTutoriels")
  const pagesVoieLactee = obtenirPages(resultatsRequete, "pagesVoieLactee")
  const pagesAuroresBoreales = obtenirPages(
    resultatsRequete,
    "pagesAuroresBoreales"
  )
  const pagesAstrophotographieCMOSCCD = obtenirPages(
    resultatsRequete,
    "pagesAstrophotographieCMOSCCD"
  )
  const pagesLogicielsMateriels = obtenirPages(
    resultatsRequete,
    "pagesLogicielsMateriels"
  )
  const pagesAutresArticles = obtenirPages(
    resultatsRequete,
    "pagesAutresArticles"
  )

  return (
    <LayoutFR page={pageIndexTutoriels}>
      <Section titre="Voie lactée">
        <ListeCartesTitreEtDescription
          pages={pagesVoieLactee}
          id="voie-lactee-nav"
        />
      </Section>
      <Section titre="Aurores boréales">
        <ListeCartesTitreEtDescription
          pages={pagesAuroresBoreales}
          id="aurores-boreales-nav"
        />
      </Section>
      <Section titre="Astrophotographie CMOS et CCD">
        <ListeCartesTitreEtDescription
          pages={pagesAstrophotographieCMOSCCD}
          id="astrophotographie-cmos-ccd-nav"
        />
      </Section>
      <Section titre="Logiciels et matériels">
        <ListeCartesTitreEtDescription
          pages={pagesLogicielsMateriels}
          id="logiciels-materiels-nav"
        />
      </Section>
      <Section titre="Autres articles">
        <ListeCartesTitreEtDescription
          pages={pagesAutresArticles}
          id="autres-articles-nav"
        />
      </Section>
    </LayoutFR>
  )
}
